import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Slideshow.css";

import Slide from "./Slide";

function shuffle(a: string[]): string[] {
  const b = Array.from(a);
  let j, x, i;
  for (i = b.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = b[i];
    b[i] = b[j];
    b[j] = x;
  }
  return b;
}

type Props = {
  imgUrls: string[];
  alt?: string;
  duration?: number;
  endImgUrl?: string;
  randomize?: boolean;
};

const defaultDuration = 4000;

const Slideshow = (props: Props): React.ReactElement => {
  const [images] = useState<string[]>(() =>
    !!props.randomize ? shuffle(props.imgUrls) : props.imgUrls
  );
  const [showEndUrl, setShowEndUrl] = useState<boolean>(false);
  const duration = props.duration || defaultDuration;

  const slideshowRef = useRef<HTMLDivElement>(null);
  const slideshowContainerRef = useRef<HTMLDivElement>(null);

  const disableFullscreen = (): void => {
    document.exitFullscreen().catch(() => {
      // ignored
    });
  };

  const enableFullscreen = (): void => {
    slideshowRef.current?.requestFullscreen().catch(() => {
      // ignored
    });
  };

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const incIndex = useCallback((): void => {
    if (showEndUrl) {
      return;
    }
    const idx = (currentIndex + 1) % images.length;
    setCurrentIndex(() => idx);
  }, [images, currentIndex, showEndUrl]);

  const decIndex = useCallback((): void => {
    if (showEndUrl) {
      return;
    }
    let idx = (currentIndex - 1) % images.length;
    if (idx < 0) {
      idx = images.length - 1;
    }
    setCurrentIndex(() => idx);
  }, [images, currentIndex, showEndUrl]);

  const showEndSlide = useCallback((): void => {
    if (!props.endImgUrl) {
      return;
    }
    setShowEndUrl(true);
  }, [props.endImgUrl]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent): void => {
      switch (e.key) {
        case "Escape":
          disableFullscreen();
          break;
        case "e":
          showEndSlide();
          break;
        case "f":
          enableFullscreen();
          break;
        case "ArrowRight":
          setShowEndUrl(false);
          incIndex();
          break;
        case "ArrowLeft":
          setShowEndUrl(false);
          decIndex();
          break;
      }
    },
    [decIndex, incIndex, showEndSlide]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      incIndex();
    }, duration);
    return (): void => {
      clearTimeout(timeout);
    };
  }, [duration, incIndex, showEndUrl]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return (): void => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown, currentIndex, showEndUrl]);

  const slides = images.map((url: string, index: number) => {
    return (
      <Slide
        key={url}
        url={url}
        alt={props.alt || ""}
        visible={index === currentIndex && !showEndUrl}
      />
    );
  });

  const endSlide = props.endImgUrl ? (
    <Slide
      key={props.endImgUrl}
      url={props.endImgUrl}
      alt={props.alt || ""}
      visible={showEndUrl}
    />
  ) : null;

  return (
    <div ref={slideshowRef}>
      <div ref={slideshowContainerRef} className="slideshow-container">
        {slides}
        {endSlide}
      </div>
    </div>
  );
};

export default Slideshow;
