import React from "react";

import "./Slide.css";

export interface SlideImage {
  url: string;
  visible: boolean;
  alt?: string;
}

const Slide = (props: SlideImage): React.ReactElement => {
  const { url, visible, alt } = props;
  let className = "image-container";
  if (!visible) {
    className = `${className} hidden`;
  }

  return (
    <div className={className}>
      <img alt={alt || ""} src={url} />
    </div>
  );
};

export default Slide;
